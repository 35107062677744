import { useEffect, useState, useRef } from 'react';
import {
  BooleanInput, DateInput, Edit, FormWithRedirect, NumberInput, SaveButton, SelectInput, TextInput, useDataProvider,
  useNotify, useRedirect, useRefresh, SimpleFormIterator, ArrayInput, usePermissions, useEditController, useMutation,
} from 'react-admin';
import { InputAdornment, Grid, Divider, Typography, Button, ButtonGroup, Box, CardHeader, CardContent, Card } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { EditSharp, WhatsApp } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import { shape, object, string, number, func } from 'prop-types';

import {
  formatChoices, GENDER, RESIDENCE_DURATION, phoneNumber, EMPLOYMENT_POSITIONS, validateNIDA, validateText,
  clearEmptyFormValues, applyMask,
} from '../../../utils';
import { ChangePhoneNumberDialog, CreateApplicationDialog } from '../../dialogs';
import OldPhonesDialog from '../../old_phones/OldPhonesDialog';
import { Call } from '../../Call';
import { useHandbook } from '../../../hooks';
import { BlackListMatchComponent, BlackListComponent } from '../../black_list';
import { CURRENCY, COUNTRY_CODE, NIDA_PLACEHOLDER } from '../../../constants';
import { DocPhotoCard } from '../../DocPhotoCard';

import { UserEditTransitions } from './UserEditTransitions';
import { UserEditAdditionalInfo } from './UserEditAdditionalInfo';

const UserEditForm = ({ formProps, record, activeTabIndex, setActiveTabIndex }) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const isUserEditPermitted = permissions.includes('CAN_USER_EDIT');
  const isApplicationEditPermitted = permissions.includes('CAN_APPLICATION_EDIT');
  const isUserProfileNICEditPermitted = permissions.includes('CAN_USER_PROFILE_NIC_EDIT');
  const isPhoneNumberEditPermitted = permissions.includes('CAN_PHONE_NUMBER_EDIT');

  const [isPrevPhonesDialogOpen, setIsPrevPhonesDialogOpen] = useState(false);
  const [isChangePhoneNumberDialogOpen, setIsChangePhoneNumberDialogOpen] = useState(false);
  const [regionDistricts, setRegionDistricts] = useState([]);
  const [isMonthlyPaysShow, setIsMonthlyPaysShow] = useState(false);
  const [isCreateAppDialogOpen, setIsCreateAppDialogOpen] = useState(false);
  const isApplicationCreatable = isApplicationEditPermitted && isCreateAppDialogOpen;

  const isInBL = formProps.record.is_in_black_list;
  const isBLMatch = !isInBL && formProps.record.is_find_in_black_list;
  const { choices: educationChoices } = useHandbook('education');
  const { choices: maritalStatusChoices } = useHandbook('marital_statuses');
  const { choices: employmentTypeChoices } = useHandbook('employments');
  const { choices: industriesOptions } = useHandbook('employment/industry_types');
  const { data: loanPurposes } = useHandbook('profile/loan_purposes');
  const { choices: contactRelationshipChoices } = useHandbook('phone_relations');
  const { data: regions, choices: regionsChoices } = useHandbook('regions');
  const { choices: banksOptions } = useHandbook('payment/bank_list');

  useEffect(() => {
    setIsMonthlyPaysShow(record.external_loan_monthly_payments);
  }, [record.external_loan_monthly_payments]);

  useEffect(() => {
    if (regions && regions.length > 0 && record.residence_region) {
      const [selectedRegion] = regions.filter((region) => region.name === record.residence_region);
      const selectedRegionDistrictsOptions = [];
      selectedRegion.districts.map((district) => selectedRegionDistrictsOptions.push({ name: district.name, id: district.name }));
      setRegionDistricts(selectedRegionDistrictsOptions);
    }
  }, [regions, record.residence_region]);

  const handleChangeRegion = (e) => {
    const selectedRegion = e.target.value;
    const [targetObj] = regions.filter((region) => region.name === selectedRegion);
    const regionDistricts = targetObj.districts.map((district) => ({ id: district.name, name: district.name }));
    setRegionDistricts(regionDistricts);
  };

  const handleToggleMonthlyPayments = (value) => {
    if (!value) {
      formProps.form.change('external_loan_monthly_payments', null);
    }
    setIsMonthlyPaysShow(value);
  };
  const handleCreateAppDialogClose = () => setIsCreateAppDialogOpen(false);
  const handleCreateAppDialogOpen = () => setIsCreateAppDialogOpen(true);
  const handlePrevPhonesDialogOpen = () => setIsPrevPhonesDialogOpen(true);
  const handlePrevPhonesDialogClose = () => setIsPrevPhonesDialogOpen(false);
  const handleChangePhoneNumberDialogOpen = () => setIsChangePhoneNumberDialogOpen(true);
  const handleChangePhoneNumberDialogClose = () => setIsChangePhoneNumberDialogOpen(false);
  const handleUserListNavigate = () => redirect('/users');
  const submitPhone = (phone_number) => {
    dataProvider.fetch(`users/${record.id}/phone_number`, { body: JSON.stringify(phone_number), method: 'PATCH' })
      .then(() => {
        notify('Success: Phone number was updated', 'success');
        handleChangePhoneNumberDialogClose();
        refresh();
      })
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };
  const handleCallWhatsApp = () => window.open(`whatsapp://send?phone=${COUNTRY_CODE}${formProps.record.phone_number}`, '_blank');

  if (record.registration_step && record.registration_step >= 7) {
    formProps.form.change('payment_type', 'wallet');
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={'20px'}>
      {isApplicationEditPermitted && <UserEditTransitions record={formProps.record} refresh={refresh} />}
      {isInBL && <BlackListMatchComponent summary={'User in blacklist'} findInBlackList={formProps.record.find_in_black_list} username={formProps.record.username} />}
      <UserEditAdditionalInfo user={formProps.record} activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} />
      <Divider />
      {isBLMatch && <BlackListMatchComponent findInBlackList={formProps.record.find_in_black_list} username={formProps.record.username} />}
      {isInBL && (
        <BlackListComponent
          createdBy={formProps.record.black_list.createdBy}
          createdAt={formProps.record.black_list.createdAt}
          comment={formProps.record.black_list.comment}
          reason={formProps.record.black_list.reason}
        />
      )}
      <form>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <Box padding={'10px 20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant={'h5'} gutterBottom={false}>Profile</Typography>
                    <BooleanInput
                      disabled={!isUserEditPermitted}
                      label={'Consent given?'}
                      source={'is_data_processing_consent_given'}
                      fullWidth
                      helperText={false}
                    />
                  </Box>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          variant={'outlined'}
                          disabled={!isUserEditPermitted}
                          source={'first_name'}
                          fullWidth
                        />
                        <TextInput
                          variant={'outlined'}
                          disabled
                          source={'phone_number'}
                          fullWidth
                          placeholder={phoneNumber.placeholder}
                          parse={phoneNumber.parser}
                          format={phoneNumber.formatter}
                        />
                        {isPrevPhonesDialogOpen &&
                          <OldPhonesDialog userId={record.id} isOpen={isPrevPhonesDialogOpen} onClose={handlePrevPhonesDialogClose}/>}
                        {isChangePhoneNumberDialogOpen && <ChangePhoneNumberDialog
                          fieldName={'phone_number'}
                          isOpened={isChangePhoneNumberDialogOpen}
                          onClose={handleChangePhoneNumberDialogClose}
                          onSubmit={(phone_number) => submitPhone(phone_number)}
                        />}
                        <TextInput
                          variant={'outlined'}
                          disabled={!isUserProfileNICEditPermitted || !isUserEditPermitted}
                          validate={validateNIDA.validate}
                          parse={validateNIDA.parser}
                          format={validateNIDA.formatter}
                          source={'nida_id'}
                          fullWidth
                        />
                        <DateInput variant={'outlined'} disabled={!isUserEditPermitted} label={'Birthday'} source={'birthday'} fullWidth />
                        <NumberInput
                          variant={'outlined'}
                          disabled={!isUserEditPermitted || !isMonthlyPaysShow}
                          label={'Monthly payments'}
                          source={'external_loan_monthly_payments'}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position={'end'}>{CURRENCY}</InputAdornment>
                            ),
                          }}
                        />
                        <BooleanInput
                          disabled={!isUserEditPermitted}
                          label={'Has external loan?'}
                          source={'has_external_loan'}
                          fullWidth
                          color={'secondary'}
                          onChange={handleToggleMonthlyPayments}
                        />
                        <TextInput
                          variant={'outlined'}
                          disabled={true}
                          source={'target_url'}
                          multiline={true}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          variant={'outlined'}
                          disabled={!isUserEditPermitted}
                          validate={validateText.validate}
                          source={'last_name'}
                          fullWidth
                        />
                        <ButtonGroup size={'small'} style={{ margin: '8px 0 28px', minHeight: '40px' }} variant={'outlined'} color={'secondary'}>
                          <Button onClick={handleChangePhoneNumberDialogOpen} disabled={!isPhoneNumberEditPermitted}>
                            <EditSharp />
                          </Button>
                          <Call userId={formProps.record.id} userPhone={formProps.record.username} />
                          <Button onClick={handleCallWhatsApp}>
                            <WhatsApp />&nbsp;WhatsApp
                          </Button>
                          <Button onClick={handlePrevPhonesDialogOpen}>Old phone numbers</Button>
                        </ButtonGroup>
                        <TextInput
                          variant={'outlined'}
                          disabled={!isUserEditPermitted}
                          label={'Email Address'}
                          source={'email'}
                          type={'email'}
                          fullWidth
                        />
                        <SelectInput
                          variant={'outlined'}
                          disabled={!isUserEditPermitted}
                          label={'Gender'}
                          source={'gender'}
                          fullWidth
                          choices={formatChoices(GENDER)}
                        />
                        {(maritalStatusChoices && maritalStatusChoices.length > 0)
                          ? <SelectInput
                            variant={'outlined'}
                            disabled={!isUserEditPermitted}
                            label={'Marital status'}
                            source={'marital_status'}
                            fullWidth
                            choices={maritalStatusChoices}
                          />
                          : <Skeleton variant={'rect'} />
                        }
                        <TextInput
                          variant={'outlined'}
                          label={'Affiliate'}
                          source={'affiliate'}
                          disabled
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DocPhotoCard title={'selfie'} record={record} isEditable={isUserEditPermitted} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DocPhotoCard title={'id_card'} record={record} isEditable={isUserEditPermitted} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title={'Residence'} />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {(regionsChoices && regionsChoices.length > 0)
                          ? <SelectInput
                            variant={'outlined'}
                            disabled={!isUserEditPermitted}
                            label={'Region'}
                            source={'residence_region'}
                            fullWidth
                            onChange={handleChangeRegion}
                            choices={regionsChoices}
                          />
                          : <Skeleton variant={'rect'} />
                        }
                        <TextInput
                          variant={'outlined'}
                          disabled={!isUserEditPermitted}
                          label={'City'}
                          source={'residence_city'}
                          fullWidth />
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <TextInput
                              variant={'outlined'}
                              disabled={!isUserEditPermitted}
                              label={'House'}
                              source={'residence_external_house'}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextInput
                              variant={'outlined'}
                              disabled={!isUserEditPermitted}
                              label={'Flat'}
                              source={'residence_flat'}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        {(regionsChoices && regionsChoices.length > 0)
                          ? <SelectInput
                            variant={'outlined'}
                            disabled={!isUserEditPermitted || !(regionDistricts && regionDistricts.length > 0)}
                            label={'District'}
                            source={'residence_district'}
                            fullWidth
                            choices={regionDistricts}
                          />
                          : <Skeleton variant={'rect'} />}
                        <TextInput
                          disabled={!isUserEditPermitted}
                          label={'Street'}
                          variant={'outlined'}
                          source={'residence_street'}
                          fullWidth
                        />
                        <SelectInput
                          variant={'outlined'}
                          disabled={!isUserEditPermitted}
                          label={'Residence time'}
                          source={'residence_duration'}
                          fullWidth
                          choices={formatChoices(RESIDENCE_DURATION)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title={'Employment and Payment info'} />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {(banksOptions && banksOptions.length > 0)
                          ? <SelectInput
                            variant={'outlined'}
                            disabled={!isUserEditPermitted}
                            label={'Bank name'}
                            source={'bank_name'}
                            fullWidth
                            choices={banksOptions}
                          />
                          : <Skeleton variant={'rect'} />}
                        {(educationChoices && educationChoices.length > 0)
                          ? <SelectInput
                            variant={'outlined'}
                            disabled={!isUserEditPermitted}
                            label={'Education'}
                            source={'education'}
                            fullWidth
                            choices={educationChoices}
                          />
                          : <Skeleton variant={'rect'} />}
                        {(employmentTypeChoices && employmentTypeChoices.length > 0)
                          ? <SelectInput
                            variant={'outlined'}
                            disabled={!isUserEditPermitted}
                            label={'Employment type'}
                            source={'employment_type'}
                            fullWidth
                            choices={employmentTypeChoices}
                          />
                          : <Skeleton variant={'rect'} />}
                        <TextInput
                          variant={'outlined'}
                          source={'employment_company_phone_number'}
                          fullWidth
                          placeholder={phoneNumber.placeholder}
                          parse={phoneNumber.parser}
                          format={phoneNumber.formatter}
                          label={'Company phone'}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <NumberInput
                              InputLabelProps={{ shrink: true }}
                              variant={'outlined'}
                              disabled={!isUserEditPermitted}
                              label={'Duration of work'}
                              source={'employment_duration'}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>months</InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <NumberInput
                              InputLabelProps={{ shrink: true }}
                              variant={'outlined'}
                              disabled={!isUserEditPermitted}
                              label={'Monthly income'}
                              source={'monthly_income'}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position={'end'}>{CURRENCY}</InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        {(loanPurposes && loanPurposes.length > 0)
                          ? <SelectInput
                            variant={'outlined'}
                            disabled={!isUserEditPermitted}
                            label={'Loan purpose'}
                            source={'loan_purpose'}
                            fullWidth
                            choices={formatChoices(loanPurposes)}
                          />
                          : <Skeleton variant={'rect'} />}
                        {(industriesOptions && industriesOptions.length > 0)
                          ? <SelectInput
                            variant={'outlined'}
                            disabled={!isUserEditPermitted}
                            label={'Working industry'}
                            source={'employment_industry'}
                            fullWidth
                            choices={industriesOptions}
                          />
                          : <Skeleton variant={'rect'} />}
                        <SelectInput
                          variant={'outlined'}
                          disabled={!isUserEditPermitted}
                          label={'Job position / designation'}
                          source={'employment_position'}
                          fullWidth
                          choices={EMPLOYMENT_POSITIONS}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title={'Additional contacts'} />
                  <Divider />
                  <ArrayInput source={'additional_contacts'} label={''}>
                    <SimpleFormIterator disableReordering={true}>
                      <TextInput
                        variant={'outlined'}
                        disabled={!isUserEditPermitted}
                        label={'Name'}
                        fullWidth
                        source={'contact_name'}
                      />
                      {(contactRelationshipChoices && contactRelationshipChoices.length > 0)
                        ? <SelectInput
                          variant={'outlined'}
                          disabled={!isUserEditPermitted}
                          label={'Relationship'}
                          fullWidth
                          source={'contact_relationship'}
                          choices={contactRelationshipChoices}
                        />
                        : <Skeleton variant={'rect'} />}
                      <TextInput
                        variant={'outlined'}
                        disabled={!isUserEditPermitted}
                        label={'Phone number'}
                        source={'contact_phone_number'}
                        fullWidth
                        placeholder={phoneNumber.placeholder}
                        parse={phoneNumber.parser}
                        format={phoneNumber.formatter}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup variant={'contained'} color={'primary'}>
              <Button startIcon={<ListIcon />} onClick={handleUserListNavigate}>Back to list</Button>
              {(isApplicationEditPermitted && !isInBL) &&
                <Button startIcon={<AddBoxIcon />} onClick={handleCreateAppDialogOpen}>Create application</Button>}
              {isUserEditPermitted && <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />}
            </ButtonGroup>
          </Grid>
        </Grid>
      </form>
      {(isApplicationCreatable && isCreateAppDialogOpen) &&
        <CreateApplicationDialog isOpened={isCreateAppDialogOpen} onClose={handleCreateAppDialogClose} record={formProps.record} />}
    </Box>
  );
};

UserEditForm.propTypes = {
  formProps: object,
  record: shape({
    external_loan_monthly_payments: number,
    residence_region: string,
  }),
  activeTabIndex: number,
  setActiveTabIndex: func,
};

export const UserEdit = (props) => {
  const notify = useNotify();
  const [mutate, { loading: saveLoading, error: saveError }] = useMutation();
  const { record, error, loaded } = useEditController(props);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleSave = (data) => {
    let payload = clearEmptyFormValues(data);
    const idedContacts = payload?.additional_contacts.map((item) => ({ ...item, contact_phone_id: item?.contact_phone_id || '' }));
    payload = {
      ...payload,
      nida_id: data.nida_id.includes('-') ? data.nida_id : applyMask(data.nida_id, NIDA_PLACEHOLDER),
      additional_contacts: idedContacts,
    };
    mutate({ type: 'update', resource: 'users', payload: { id: record?.id, data: { ...payload, _params: { method: 'PATCH' } } } },
      {
        onSuccess: () => notify('User updated successfully:)', 'success'),
        onFailure: (error) => notify(`Error: ${error.message || 'User update failed:('}`, 'error'),
      },
    );
  };
  if (error && loaded) return <Alert color={'error'}>{'Can\'t load user:('}</Alert>;
  return (
    <Edit component={'div'} mutationMode={'pessimistic'} {...props}>
      <FormWithRedirect initialValues={{}} save={handleSave} render={(formProps) => <UserEditForm record={record} formProps={formProps} activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} />} />
    </Edit>
  );
};
