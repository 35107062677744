import { format } from 'date-fns';
import { useNotify, useGetOne } from 'react-admin';
import { Link, CircularProgress, ButtonGroup, Button, Box, Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';
import { number, bool } from 'prop-types';

import { formatCurrency, formatPhoneNumber } from '../../utils';
import { Call } from '../Call';
import { TabPanel } from '../TabPanel';
import DocumentVerification from '../document_verification/DocumentVerification';
import SocialMedia from '../social_media/SocialMedia';
import SocialMediaSeon from '../social_media/SocialMediaSeon';
import { BlackListMatchComponent, BlackListComponent } from '../black_list';
import { COUNTRY_CODE } from '../../constants';
import { ListItem } from '../ListItem';
import { DocPhotoCard } from '../DocPhotoCard';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

const rowData = (name, value) => ({ name, value });

export const UserInfoTabCard = ({ user_id, isPermitted }) => {
  const notify = useNotify();
  const { data: record, loading, error } = useGetOne('users', user_id);
  const isBL = record && record.is_in_black_list;
  const isMatchBL = record && !isBL && record.is_find_in_black_list;
  const handleWhatsAppCall = () => window.open(`whatsapp://send?phone=${COUNTRY_CODE}${record.phone_number}`, '_blank');

  const profileRows = record
    ? [
      rowData('ID', <Link color={'secondary'} href={`#users/${record.id}`}>{record.id}</Link>),
      rowData('First name', record?.first_name),
      rowData('Last name', record?.last_name),
      rowData('Phone number', <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} gridGap={'5px'}>
        <p>{formatPhoneNumber(record.phone_number)}</p>
        <ButtonGroup color={'secondary'} size={'small'}>
          <Call userId={record.id} userPhone={record.phone_number} />
          <Button onClick={handleWhatsAppCall}><WhatsApp /></Button>
        </ButtonGroup></Box>),
      rowData('Is data processing consent given', record?.is_data_processing_consent_given ? 'Yes' : 'No'),
      rowData('NIDA', record?.nida_id),
      rowData('Gender', record?.gender),
      rowData('Birthday', record.birthday && <DateTimeBoxComponent inline value={record.birthday} />),
      rowData('Email', record?.email),
      rowData('Education', record?.education),
      rowData('Main source of income', record?.main_source_of_income),
      rowData('Monthly income', record?.monthly_income && formatCurrency(record.monthly_income)),
      rowData('Marital status', record?.marital_status),
      rowData('In blacklist', record?.is_in_black_list ? 'Yes' : 'No'),
    ]
    : [];

  const residenceRows = record
    ? [
      rowData('Region', record?.residence_region),
      rowData('District', record?.residence_district),
      rowData('Street', record?.residence_street),
      rowData('House', record?.residence_external_house),
      rowData('Flat', record?.residence_flat),
      rowData('Duration', record?.residence_duration),
    ]
    : [];

  const employmentRows = record
    ? [
      rowData('Employment type', record?.employment_type),
      rowData('Employment company name', record?.employment_company_name),
      rowData('Employment company phone number', record?.employment_company_phone_number),
      rowData('Employment duration', record?.employment_duration),
      rowData('Employment industry', record?.employment_industry),
      rowData('Employment position', record?.employment_position),
      rowData('Employment social security number', record?.employment_social_security_number),
    ]
    : [];

  const tabs = [
    {
      label: 'Profile',
      value: null,
      hasPermission: true,
      component: profileRows.map((row, key) =>
        <ListItem isLast={key === profileRows.length - 1} key={key} label={row.name} value={row.value} />),
    },
    {
      label: 'Residence & Employment',
      value: null,
      hasPermission: true,
      component: <Box display={'flex'} flexDirection={'column'} gridGap={'20px'}>
        <Card>
          <CardHeader title={'Residence'}/>
          {residenceRows.map((row, key) => <ListItem key={key} isLast={key === residenceRows.length - 1} label={row.name} value={row.value} />)}
        </Card>
        <Card>
          <CardHeader title={'Employment'} />
          {employmentRows.map((row, key) => <ListItem key={key} isLast={key === employmentRows.length - 1} label={row.name} value={row.value} />)}
        </Card>
      </Box>,
    },
    {
      label: 'Contact & Bank',
      value: null,
      hasPermission: true,
      component: <Box display={'flex'} flexDirection={'column'} gridGap={'20px'}>
        <Card>
          <CardHeader title={'Bank data'}/>
          <ListItem isLast label={'Bank name'} value={record?.bank_name}/>
        </Card>
        <Card>
          <CardHeader title={'Contacts'}/>
          {record?.additional_contacts?.map((contact, key) =>
            <>
              <ListItem key={key} label={'Name'} value={contact.contact_name} />
              <ListItem key={key} label={'Relationship'} value={contact.contact_relationship} />
              <ListItem isLast key={key} label={'Phone Number'} value={<Call userId={contact?.id} userPhone={contact.contact_phone_number}>{formatPhoneNumber(contact.contact_phone_number)}</Call>} />
            </>)}
        </Card>
      </Box>,
    },
    {
      label: 'Photo',
      value: null,
      hasPermission: true,
      component: <Box display={'flex'} flexDirection={'column'} gridGap={'20px'}>
        <DocPhotoCard title={'selfie'} record={record} isEditMode={false} />
        <DocPhotoCard title={'id_card'} record={record} isEditMode={false} />
      </Box>,
    },
    {
      label: 'Document verification',
      value: null,
      hasPermission: true,
      component: <DocumentVerification userId={user_id} />,
    },
    {
      label: 'Social Media (Seon)',
      value: null,
      hasPermission: true,
      component: <SocialMediaSeon userId={user_id} />,
    },
    {
      label: 'Social Media',
      value: null,
      hasPermission: true,
      component: <SocialMedia userId={user_id} />,
    },
  ];

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  return (isPermitted && record
    ? <>
      {isMatchBL && <BlackListMatchComponent findInBlackList={record.find_in_black_list} username={record.username} />}
      {isBL && (
        <BlackListComponent
          createdBy={record.black_list.createdBy}
          createdAt={record.black_list.createdAt}
          comment={record.black_list.comment}
          reason={record.black_list.reason}
        />
      )}
      <TabPanel isClosable={false} items={tabs} />
    </>
    : <Card>
      <CardHeader title={'User info'} />
      <CardContent>
        <Typography variant={'body1'}>-- You do not have sufficient rights to view customer details --</Typography>
      </CardContent>
    </Card>
  );
};

UserInfoTabCard.propTypes = {
  user_id: number,
  isPermitted: bool,
};
