export const ROBOCALL_PROVIDERS = [
  { id: 'ai_rudder', name: 'ai_rudder' },
];
export const ROBOCALL_LANGUAGE = [
  { id: 'en-TZ', name: 'en-TZ' },
];
export const EN_LANGUAGE = [
  { id: 'en_TZ', name: 'en_TZ' },
];

export const GENDER = ['male', 'female'];

export const RESIDENCE_DURATION = [
  'up to three month',
  'from 3 to 6 months',
  'from 6 to 12 months',
  'more then a year',
];

export const EMPLOYMENT_POSITIONS = [
  { name: 'Staff', id: 'staff' },
  { name: 'Manager', id: 'manager' },
  { name: 'Director', id: 'director' },
  { name: 'Business Owner', id: 'business_owner' },
  { name: 'Other', id: 'other' },
];

export const USER_EVENT = [
  { id: 'user_phone_confirmed', name: 'User phone confirmed' },
  { id: 'user_registration_dropped', name: 'User registration dropped' },
  { id: 'application_created', name: 'Application created' },
  { id: 'application_rejected', name: 'Application rejected' },
  { id: 'application_approved', name: 'Application approved' },
  { id: 'application_confirmed', name: 'Application confirmed' },
  { id: 'application_manual_verification', name: 'Application manual verification' },
  { id: 'loan_activated', name: 'Loan activated' },
  { id: 'loan_closed', name: 'Loan closed' },
  { id: 'loan_defaulted', name: 'Loan defaulted' },
  { id: 'extension_activated', name: 'Extension activated' },
  { id: 'income_processed', name: 'Income processed' },
  { id: 'extension_5th_activated', name: '5th extension activated' },
  { id: 'extension_10th_activated', name: '10th extension activated' },
  { id: 'user_reg_dropped_after_step_4', name: 'User registration dropped after the 4th step' },
  { id: 'user_reg_dropped_after_step_6', name: 'User registration dropped after the 6th step' },
  { id: 'user_reg_dropped_after_step_7', name: 'User registration dropped after the 7th step' },
  { id: 'user_reg_dropped_at_step_7', name: 'User registration dropped at the 7th step' },
  { id: 'user_reg_dropped_at_step_8', name: 'User registration dropped at the 8th step' },
];

export const TRANSMITTERSLIST_DEFAULT = [
  { id: 'fake', name: 'Fake' },
  { id: 'infobip', name: 'Infobip' },
  { id: 'mailer', name: 'E-mail' },
  { id: 'africas_talking', name: 'Africa\'s Talking' },
];

export const ALPHA_NAMES = {
  text_ware: ['CashX', 'Cash.X', 'Cash_X', 'Discount CX', 'LegalDeptCX', 'Reminder CX', 'SpecOfferCX', 'VisitTeamCX'],
  text_ware_promo: ['CashXPromo', 'PromoCashX'],
};

export const TIMEZONES = [
  'Local',
  'Africa/Dar_es_Salaam',
  'UTC',
];

export const TIMEZONE_OFFSET = {
  Local: () => new Date().getTimezoneOffset() * -1,
  'Africa/Dar_es_Salaam': () => 180,
  UTC: () => 0,
};

export const AVENTUS_DECISION_ENGINE_STATUSES = [
  { id: 1, name: 'approve' },
  { id: 2, name: 'manual verif' },
  { id: 3, name: 'reject' },
  { id: 5, name: 'in progress' },
  { id: 6, name: 'needs cb creditinfo' },
  { id: 7, name: 'needs seon' },
];

export const AUTO_ASSIGNMENT_CONTEXT = [
  { id: 'verification', name: 'Verification' },
  { id: 'telesales', name: 'Telesales' },
  { id: 'collection', name: 'Collection' },
];

export const CALL_CENTER_USER_STATUS_UNCOMPLETED_REG = [
  'proceed registration',
  'Sent the app',
  'thinking',
  'not the client\'s number',
  'reject',
  'hang the phone',
  'call back',
  'can\'t be reached',
  'Un-Contactable',
  'Temp Hold',
  'Not Intrested',
  'Complete',
  'Attachments are pending',
  'Requested for selfie',
  'Requested for NIC',
  'Requested for Passbook',
  'Pending account Number',
];

export const CALL_CENTER_USER_STATUS_CLOSED_LOANS = [
  'Already Processed - PV',
  'Already Rejected - PV',
  'call back',
  'Complete',
  'Not Interested',
  'Pending Details',
  'Rejected',
  'Temp Hold',
  'Un-Contactable',
];

export const CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS = [
  'Not selected',
  'Is not interested',
  'Not answered',
  'Is thinking',
  'Don\'t like the interest rate',
  'Don\'t like the approved amount',
  'Going to sign',
];

export const TEMPLATE_TYPES = [
  { id: 'System', name: 'System' },
  { id: 'Marketing', name: 'Marketing' },
  { id: 'Collection', name: 'Collection' },
];

export const TEMPLATE_CATEGORIES = [
  { id: 'SMS', name: 'SMS' },
  { id: 'Email', name: 'Email' },
  { id: 'PDF', name: 'PDF' },
];

export const CHOICES = [
  { id: 'marketing', name: 'Marketing' },
  { id: 'call-center', name: 'Call center' },
  { id: 'collection', name: 'Collection' },
];

export const AUDIENCE_FILTER_CONDITION_CHOICES = [
  { id: '==', name: '==' },
  { id: '<', name: '<' },
  { id: '>', name: '>' },
  { id: '<=', name: '<=' },
  { id: '>=', name: '>=' },
];

export const AUDIENCE_FILTER_INCLUDE_CONDITION_CHOICES = [
  { id: 'include', name: 'Include' },
  { id: 'exclude', name: 'Exclude' },
];

export const AUDIENCE_FILTERS_IDS = [
  { id: 'registration_date', title: 'Registration date', type: 'date_range' },
  { id: 'user_is_blocked', title: 'User is blocked', type: 'boolean' },
  { id: 'user_in_blacklist', title: 'User in blacklist', type: 'boolean' },
  { id: 'last_application_rejection_reason', title: 'Last application rejection reason', type: 'list' },
  { id: 'last_application_state', title: 'Last application state', type: 'list' },
  { id: 'user_gender', title: 'User gender', type: 'list' },
  { id: 'user_age', title: 'User age', type: 'sign' },
  { id: 'last_call_result_promo', title: 'Last call result (Promo)', type: 'list' },
  { id: 'registration_step', title: 'Registration step', type: 'list' },
  { id: 'last_loan_state', title: 'Last loan state', type: 'list' },
  { id: 'last_call_result_unfinished_registration', title: 'Last call result (Unfinished registration)', type: 'list' },
  { id: 'last_call_result_unsigned_request', title: 'Last call result (Unsigned request)', type: 'list' },
  { id: 'user_days_before_birthday', title: 'User days before birthday', type: 'sign' },
  { id: 'closed_loan_number', title: 'Closed loan number', type: 'sign' },
  { id: 'days_since_last_rejected_application', title: 'Days since last rejected application', type: 'sign' },
  { id: 'days_since_last_user_unlock', title: 'Days since last user unlock', type: 'sign' },
  { id: 'days_without_loan', title: 'Days without loan', type: 'sign' },
  { id: 'extension_number', title: 'Extension number', type: 'sign' },
  { id: 'last_loan_max_dpd', title: 'Last loan max days past due', type: 'sign' },
  { id: 'last_moratorium_days', title: 'Last moratorium days', type: 'sign' },
  { id: 'last_user_activity', title: 'Last user activity (minutes)', type: 'sign' },
  { id: 'last_sent_sms_template', title: 'Template of the last sent sms', type: 'list' },
  { id: 'days_since_last_sms_sent', title: 'Days since last sms was sent', type: 'sign' },
  { id: 'last_loan_sequence_number', title: 'Sequence number of last loan', type: 'sign' },
  { id: 'last_loan_has_active_promo_code', title: 'Last loan with active promo code (conditions)', type: 'boolean' },
  { id: 'last_loan_term', title: 'Term of last loan', type: 'sign' },
  { id: 'last_loan_type', title: 'Type of last loan', type: 'list' },
  { id: 'income_for_loan', title: 'Income for loan', type: 'boolean' },
  { id: 'days_from_the_last_pay', title: 'Days from the last pay', type: 'sign' },
  { id: 'closed_loan', title: 'Closed load', type: 'sign' },
  { id: 'amount_loan', title: 'Amount of loan', type: 'sign' },
  { id: 'days_after_PTP_date', title: 'Days after PTP date', type: 'sign' },
  { id: 'days_before_due_date', title: 'Days before due date', type: 'sign' },
  { id: 'days_before_PTP_date', title: 'Days before PTP date', type: 'sign' },
  { id: 'user_has_do_not_call_marker', title: 'User has \'do not call\' marker', type: 'boolean' },
  {
    id: 'registration_date_from_today',
    title: 'Registration date from today(day interval, 0 - today, -1 - yesterday, etc)',
    type: 'sign',
  },
  { id: 'last_loan_collection_score_group', title: 'Last loan collection score group', type: 'list' },
  { id: 'last_loan_collection_score_priority', title: 'Last loan collection score priority', type: 'list' },
  { id: 'dpd', title: 'DPD', type: 'sign' },
  { id: 'collection_group', title: 'Loan Stage', type: 'list' },
  { id: 'last_loan_tenor', title: 'Last loan tenor', type: 'sign' },
  { id: 'collector', title: 'Collector', type: 'list' },
  {
    id: 'days_from_the_last_call_client',
    title: 'Days from the last call "Contact type: Client" (phone)',
    type: 'sign',
  },
  { id: 'days_from_the_last_call_tpc', title: 'Days from the last call "Contact type: TPC" (phone)', type: 'sign' },
  { id: 'days_from_the_last_call', title: 'Days From Last call (collеction)', type: 'sign' },
  { id: 'days_from_last_ptp_date', title: 'Days From Last PTP Date (loan)', type: 'sign' },
  { id: 'ptp_stage', title: 'PTP Stage', type: 'list' },
  { id: 'total', title: 'Total', type: 'sign' },
  { id: 'principal', title: 'Principal', type: 'sign' },
  { id: 'count_of_broken_ptp', title: 'Count of broken PTP', type: 'sign' },
  { id: 'collection_result', title: 'Collection result', type: 'list' },
  { id: 'days_after_last_contact_adding', title: 'Days after adding last contact', type: 'sign' },
  { id: 'max_dpd_ever', title: 'Max DPD ever', type: 'sign' },
  { id: 'rpc_ever', title: 'RPC for last loan', type: 'boolean' },
];

export const CONTACT_STATUS_LIST = [
  { code: 'active', name: 'Active' },
  { code: 'inactive', name: 'Inactive' },
];

export const CONTACT_VALIDATION_STATE_LIST = [
  { code: 'MOBILE', name: 'Mobile' },
  { code: 'LANDLINE', name: 'Landline' },
  { code: 'VOIP', name: 'Voip' },
  { code: 'OTHER', name: 'Other' },
  { code: 'PREPAID', name: 'Prepaid' },
  { code: 'INVALID', name: 'Invalid' },
];
