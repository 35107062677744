import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DialogContent } from '@material-ui/core';

import { useDialogStyles } from './useDialogStyle';

export const TitleCloseDialog = ({ children, open, close, title, ...props }) => {
  const style = useDialogStyles();
  return (
    <Dialog open={open} onClose={() => close(v => !v)} {...props}>
      <Grid container>
        <Grid item xs={10} sm={10}>
          <Typography variant="h6" className={style.title}>{title}</Typography>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Box textAlign="right">
            <IconButton onClick={() => close(v => !v)} >
              <CloseIcon/>
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <DialogContent className={style.childrenBox}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

TitleCloseDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
