import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  TablePagination,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  ButtonGroup,
  Button,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';

import { formatPhoneNumber, snakeToHuman, CONTACT_VALIDATION_STATE_LIST } from '../../utils';
import { EditUserContactsDialog } from '../dialogs';
import { useHandbook } from '../../hooks';
import { Call } from '../Call';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
  valid: {
    backgroundColor: green[300],
  },
  invalid: {
    backgroundColor: red[300],
  },
}));

const getName = (id, list) => {
  if (!id) {
    return null;
  }

  return list.find(item => item.id === id || item.code === id)?.name ?? id;
};

export const UserContactsTable = ({ userId, refreshedAt, setRefreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [filterSources, setFilterSources] = useState([]);
  const [filterPhoneNumber, setFilterPhoneNumber] = useState('');
  const [filterName, setFilterName] = useState('');
  const [contacts, setContacts] = useState([]);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [itemData, setItemData] = useState();
  const [perPage, setPerPage] = useState(10);

  const { data: contactRelationships } = useHandbook('phone_relations');
  const { data: phoneTypes } = useHandbook('phone_types');
  const { data: phoneSources } = useHandbook('phone_sources');
  const handleDialog = (type, data) => {
    if (data !== undefined) {
      setItemData(data);
    }
    setDialogIsOpen(type);
  };

  useEffect(() => {
    setFilterSources(phoneSources.map(({ code }) => code));
  }, [phoneSources]);

  useEffect(() => {
    let filter = {
      'user.id': userId,
      source: filterSources,
    };

    if (filterPhoneNumber) {
      filter = { ...filter, phone_number: filterPhoneNumber };
    }

    if (filterName) {
      filter = { ...filter, name: filterName };
    }

    dataProvider.getList('phone_books', {
      filter,
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data, total }) => {
        setContacts(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, filterSources, filterPhoneNumber, filterName, refreshedAt, page, perPage]);

  const getStatusStyle = (status, results) => {
    if (results === false) {
      return classes.default;
    }
    if (status === 'active') {
      return classes.valid;
    }
    if (status === 'inactive') {
      return classes.invalid;
    }
  };

  const getValidationStyle = (status) => {
    if (status === 'valid') {
      return classes.valid;
    }
    if (status === 'invalid') {
      return classes.invalid;
    }
  };
  const handleCheckboxChange = (e) => {
    const code = e.target.value;
    if (filterSources.indexOf(code) > -1) {
      setFilterSources(filterSources.filter(item => item !== code));
    } else {
      setFilterSources([...filterSources, code]);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(contacts)) return null;

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Box p={2}>
        <FormGroup row>
          {phoneSources.map(({ code, name }) => (
            <FormControlLabel
              key={code}
              label={name}
              control={
                <Checkbox
                  checked={filterSources.indexOf(code) > -1}
                  onChange={handleCheckboxChange}
                  value={code}
                />
              }
            />
          ))}
        </FormGroup>
      </Box>
      <Box p={2}>
        <TextField
          id={'filter-phone-number'}
          label={'Phone number'}
          variant={'outlined'}
          size={'small'}
          value={filterPhoneNumber}
          onChange={e => setFilterPhoneNumber(e.target.value)}
        />

        <TextField
          id={'filter-name'}
          label={'Name'}
          variant={'outlined'}
          size={'small'}
          className={ classes.ml2 }
          value={filterName}
          onChange={e => setFilterName(e.target.value)}
        />
      </Box>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Phone number</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Relation</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Validation</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell align={'right'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Call
                  inactive={row.status !== 'active' || row.validation_state === 'INVALID'}
                  userId={userId}
                  userPhone={row.phone_number}
                >
                  {formatPhoneNumber(row.phone_number)}
                </Call></TableCell>
              <TableCell>{ row.name?.trim() || '---' }</TableCell>
              <TableCell>{ getName(row.type, phoneTypes) || '---' }</TableCell>
              <TableCell>{ getName(row.relation, contactRelationships) || '---' }</TableCell>
              <TableCell>{ getName(row.source, phoneSources) || '---' }</TableCell>
              <TableCell>{ row.note?.trim() || '---' }</TableCell>
              <TableCell className={getStatusStyle(row.status, row?.is_results_exists)}>{ snakeToHuman(row.status) }</TableCell>
              <TableCell className={getValidationStyle(row.validation_state)}>
                {row?.validation_state || 'None'}
              </TableCell>
              <TableCell>{row.created_at && (
                <>
                  <DateTimeBoxComponent value={row?.created_at} />
                  <br />
                  {row?.created_by_username ? `Created by ${row.created_by_username}` : 'Created by client'}
                </>
              )}</TableCell>
              <TableCell align={'right'}>
                {permissions.includes('CAN_PHONE_BOOK_EDIT') &&
                  <ButtonGroup size={'small'} color={'secondary'}>
                    <Button onClick={() => handleDialog(true, row)} color={'secondary'}>Edit</Button>
                  </ButtonGroup>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
      {permissions.indexOf('CAN_PHONE_BOOK_EDIT') !== -1 && itemData && dialogIsOpen &&
        <EditUserContactsDialog
          isOpened={dialogIsOpen}
          data={itemData}
          permissions={permissions}
          onClose={() => handleDialog(false)}
          onSubmit={(phone_number, name, type, note, relation, source, status, validation_state) => {
            const id = itemData.id;
            dataProvider
              .update('phone_books', {
                id,
                data: {
                  phone_number,
                  name,
                  type,
                  note: note?.trim() || null,
                  relation,
                  source,
                  status: status || 'active',
                  validation_state,
                },
              })
              .then(() => {
                setRefreshedAt(Date.now());
                handleDialog(false);
              })
              .catch((error) => notify(`Error: ${error.message}`, 'error'));
          }}
        />}
    </>
  );
};

UserContactsTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.string,
  setRefreshedAt: PropTypes.func,
};
