import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDataProvider, useGetList, useGetMainList, useNotify, usePermissions, useRefresh } from 'react-admin';
import { ButtonGroup, Button } from '@material-ui/core';
import { number, shape, string, func } from 'prop-types';

import { TabPanel } from '../../TabPanel';
import DebounceButton from '../../button/DebounceButton';
import * as Tables from '../../tables';
import * as Dialogs from '../../dialogs';

export const UserEditAdditionalInfo = ({ user, activeTabIndex, setActiveTabIndex }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [selfieMatchesTotal, setSelfieMatchesTotal] = useState(null);
  const [idCardMatchesTotal, setIdCardMatchesTotal] = useState(null);
  const [providersList, setProvidersList] = useState([]);
  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);
  const [isCollectionCallDialogOpen, setIsCollectionCallDialogOpen] = useState(false);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);
  const [isOcrRequestDialogOpen, setIsOcrRequestDialogOpen] = useState(false);
  const [isEditUserContactsDialogOpen, setIsEditUserContactsDialogOpen] = useState(false);
  const [isUserApiDataDialogOpen, setIsUserApiDataDialogOpen] = useState(false);

  const isNotificationViewPermitted = permissions.includes('CAN_NOTIFICATION_VIEW');
  const isNotificationEditPermitted = permissions.includes('CAN_NOTIFICATION_EDIT');
  const isApplicationViewPermitted = permissions.includes('CAN_APPLICATION_VIEW');
  const isLoanViewPermitted = permissions.includes('CAN_LOAN_VIEW');
  const isUserAPIDataViewPermitted = permissions.includes('CAN_USER_API_DATA_VIEW');
  const isUserAPIDataEditPermitted = permissions.includes('CAN_USER_API_DATA_EDIT');
  const isCollectionCallViewPermitted = permissions.includes('CAN_COLLECTION_CALL_VIEW');
  const isCollectionCallEditPermitted = permissions.includes('CAN_COLLECTION_CALL_EDIT');
  const isPhoneBookViewPermitted = permissions.includes('CAN_PHONE_BOOK_VIEW');
  const isPhoneBookEditPermitted = permissions.includes('CAN_PHONE_BOOK_EDIT');
  const isNoteViewPermitted = permissions.includes('CAN_NOTE_VIEW');
  const isNoteEditPermitted = permissions.includes('CAN_NOTE_EDIT');
  const isAgreementViewPermitted = permissions.includes('CAN_AGREEMENT_VIEW');
  const isUserViewPermitted = permissions.includes('CAN_USER_VIEW');
  const isKYCRequestViewPermitted = permissions.includes('CAN_KYC_REQUEST_VIEW');
  const isKYCRequestEditPermitted = permissions.includes('CAN_KYC_REQUEST_EDIT');
  const isCBReportViewPermitted = permissions.includes('CAN_CREDIT_BUREAU_REPORT_VIEW');
  const isOCRRequestViewPermitted = permissions.includes('CAN_OCR_REQUEST_VIEW');
  const isOCRRequestEditPermitted = permissions.includes('CAN_OCR_REQUEST_EDIT');
  const isPhoneCallViewPermitted = permissions.includes('CAN_PHONE_CALL_VIEW');
  const isDuplicatesViewPermitted = permissions.includes('CAN_DUPLICATES_VIEW');
  const isSLLogViewPermitted = permissions.includes('CAN_SHORT_LINK_LOG_VIEW');
  const { total: notificationsTotal, error: notificationsError, loaded: notificationsLoaded, loading: notificationsLoading } = useGetList('notifications', {}, {}, { 'user.id': user.id });
  const { total: loansTotal, error: loansError, loaded: loansLoaded, loading: loansLoading } = useGetMainList('loans', { page: 1, perPage: 30 }, { field: 'id', order: 'DESC' }, { 'user.id': user.id });
  const { total: appsTotal, error: appsError, loaded: appsLoaded, loading: appsLoading } = useGetMainList('applications', {}, {}, { 'user.id': user.id });
  const [loans, setLoans] = useState(null);

  useEffect(() => {
    dataProvider.getList('aws_rekognition_matches',
      {
        pagination: {},
        sort: {},
        filter: { user: user.id, 'similarity[gte]': 99 },
      })
      .then(({ total }) => setSelfieMatchesTotal(total))
      .catch((error) => notify(`Error: ${error.message}`, 'error'));

    dataProvider.getList('aws_rekognition_id_card_matches',
      {
        pagination: {},
        sort: {},
        filter: { user: user.id, 'similarity[gte]': 99 },
      })
      .then(({ total }) => setIdCardMatchesTotal(total))
      .catch((error) => notify(`Error: ${error.message}`, 'error'));

    if (isUserAPIDataEditPermitted) {
      dataProvider.query('user_api_datas/providers', { method: 'GET' })
        .then((res) => {
          if (res?.data) {
            const { data } = res;
            setProvidersList(data);
          }
        })
        .catch((error) => notify(`Error: ${error.message}`, 'error'));
    }
    if (isLoanViewPermitted) {
      dataProvider.getList('loans', {
        filter: { 'user.id': user.id },
        pagination: {},
        sort: {},
      })
        .then(({ total }) => {
          setLoans(total);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });
    }
  }, [dataProvider, notify, isUserAPIDataEditPermitted, user.id, isLoanViewPermitted]);

  const handleRefresh = () => refresh(Date.now());
  const handleNotifyError = (error) => notify(`Error: ${error.message}`, 'error');

  const handleNotificationTransitionApply = (notificationId, name, params = {}) => {
    dataProvider.query(`notifications/${notificationId}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => handleRefresh())
      .catch((error) => handleNotifyError(error))
    ;
  };
  const handleAgreementTransitionApply = (agreementId, name, params = {}) => {
    dataProvider.query(`agreements/${agreementId}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => handleRefresh())
      .catch(error => handleNotifyError(error))
    ;
  };

  const handleOCRRequestsDialogOpen = () => setIsOcrRequestDialogOpen(true);
  const handleOCRRequestDialogClose = () => setIsOcrRequestDialogOpen(false);
  const handleAddOCRRequestSubmit = (object) => {
    handleOCRRequestDialogClose();
    dataProvider.create('ocr_requests', { data: { user: `/api/users/${user.id}`, ...object } })
      .then(() => handleRefresh())
      .catch((error) => handleNotifyError(error));
  };

  const handleNotificationDialogOpen = () => setIsNotificationDialogOpen(true);
  const handleNotificationDialogClose = () => setIsNotificationDialogOpen(false);
  const handleNotificationSubmit = (transmitterId, destination, template, locale, message, subject) => {
    handleNotificationDialogClose();
    dataProvider.query(`users/${user.id}/notifications`, {
      body: JSON.stringify({
        transmitter_id: transmitterId,
        destination,
        template,
        locale,
        message,
        subject,
      }),
    })
      .then(() => refresh(Date.now()))
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const handleCollectionDialogOpen = () => setIsCollectionCallDialogOpen(true);
  const handleCollectionCallDialogClose = () => setIsCollectionCallDialogOpen(false);
  const handleCollectionCallSubmit = () => {
    handleRefresh();
    handleCollectionCallDialogClose();
  };

  const handleNoteDialogOpen = () => setIsNoteDialogOpen(true);
  const handleNoteDialogClose = () => setIsNoteDialogOpen(false);
  const handleAddNoteSubmit = (label, message) => {
    handleNoteDialogClose();
    dataProvider.create('notes', { data: { user_id: user.id, label, message } })
      .then(() => handleRefresh())
      .catch(error => handleNotifyError(error));
  };

  const handleEditUserContactsDialogOpen = () => setIsEditUserContactsDialogOpen(true);
  const handleEditUserContactsDialogClose = () => setIsEditUserContactsDialogOpen(false);
  const handleEditUserContactSubmit = (phone_number, name, type, note, relation, source, status, validation_state) => {
    handleEditUserContactsDialogClose();
    dataProvider.create('phone_books', {
      data: {
        user: user.id,
        phone_number,
        name,
        type,
        note: note?.trim() || null,
        relation,
        source,
        status: status || 'active',
        validation_state,
      },
    })
      .then(() => handleRefresh())
      .catch((error) => handleNotifyError(error));
  };

  const handleUserApiDataDialogOpen = () => setIsUserApiDataDialogOpen(true);
  const handleUserApiDataDialogClose = () => setIsUserApiDataDialogOpen(false);
  const handleAddUserApiDataSubmit = () => {
    handleUserApiDataDialogClose();
    handleRefresh();
  };

  const handleAddKYCRequestSubmit = () => {
    dataProvider.create('kyc_requests', {
      data: {
        user: `/api/users/${user.id}`,
        engine: 'seon_io',
      },
    })
      .then(() => handleRefresh())
      .catch((error) => handleNotifyError(error));
  };

  const tabs = [
    {
      label: 'Notifications',
      value: (notificationsLoaded && !notificationsError) ? notificationsTotal : null,
      hasPermission: isNotificationViewPermitted,
      component: <>
        <Tables.NotificationsTable userId={user.id} onTransition={handleNotificationTransitionApply} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isNotificationEditPermitted && <Button onClick={handleNotificationDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Calls',
      value: null,
      hasPermission: isCollectionCallViewPermitted,
      component: <>
        <Tables.CollectionCallTable userId={user.id} permissions={permissions} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {(isCollectionCallEditPermitted && isPhoneBookViewPermitted && loans > 0) &&
            <Button onClick={handleCollectionDialogOpen}>Add call</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Notes',
      value: null,
      hasPermission: isNoteViewPermitted,
      component: <>
        <Tables.NotesTable userId={user.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isNoteEditPermitted && <Button onClick={handleNoteDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Agreements',
      value: null,
      hasPermission: isAgreementViewPermitted,
      component: <>
        <Tables.AgreementsTable userId={user.id} onTransition={handleAgreementTransitionApply} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Contacts',
      value: null,
      hasPermission: isPhoneBookViewPermitted,
      component: <>
        <Tables.UserContactsTable userId={user.id} setRefreshedAt={refresh} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isPhoneBookEditPermitted && <Button onClick={handleEditUserContactsDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Applications',
      value: (appsLoaded && !appsError) ? appsTotal : null,
      hasPermission: isApplicationViewPermitted,
      component: <Fragment key={'applications'}>
        <Tables.ApplicationsTable userId={user.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </Fragment>,
    },
    {
      label: 'Loans',
      value: (loansLoaded && !loansError) ? loansTotal : null,
      hasPermission: isLoanViewPermitted,
      component: <Fragment key={'loans'}>
        <Tables.LoansTable userId={user.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </Fragment>,
    },
    {
      label: 'Changes',
      value: null,
      hasPermission: isUserViewPermitted,
      component: <>
        <Tables.ChangeHistoryTable entityId={user.id} entityField={'user'} endpoint={'user_change_histories'} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Sessions',
      value: null,
      hasPermission: isUserViewPermitted,
      component: <>
        <Tables.SessionsTable userId={user.id} returnSimilar={false} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Selfie matches',
      value: selfieMatchesTotal,
      hasPermission: isUserViewPermitted,
      component: <>
        <Tables.SelfieMatchesTable userId={user.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Id card matches',
      value: idCardMatchesTotal,
      hasPermission: isUserViewPermitted,
      component: <>
        <Tables.IdCardMatchesTable userId={user.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'KYC',
      value: null,
      hasPermission: isKYCRequestViewPermitted,
      component: <>
        <Tables.KycTable userId={user.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isKYCRequestEditPermitted && <Button onClick={handleAddKYCRequestSubmit}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Api data',
      value: null,
      hasPermission: isUserAPIDataViewPermitted,
      component: <>
        <Tables.UserApiDataTable userId={user.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isUserAPIDataEditPermitted && <Button onClick={handleUserApiDataDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Credit bureau',
      value: null,
      hasPermission: isCBReportViewPermitted,
      component: <>
        <Tables.ReportTable userId={user.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'OCR',
      value: null,
      hasPermission: isOCRRequestViewPermitted,
      component: <>
        <Tables.OcrTable userId={user.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isOCRRequestEditPermitted && <Button onClick={handleOCRRequestsDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Call center',
      value: null,
      hasPermission: isPhoneCallViewPermitted,
      component: <>
        <Tables.CallCenterLogTable filter={{ 'user.id': user.id }} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Duplicates',
      value: null,
      hasPermission: isDuplicatesViewPermitted,
      component: <>
        <Tables.DuplicateRegistrationTable userId={user.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Facebook',
      value: null,
      hasPermission: isUserViewPermitted,
      component: <Tables.FacebookTable record={user} />,
    },
    {
      label: 'Short links',
      value: null,
      hasPermission: isSLLogViewPermitted,
      component: <>
        <Tables.ShortLinkTable userId={user.id} returnSimilar={false} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
  ];

  const filteredTabs = tabs.filter((item) => item.hasPermission);
  return (
    <>
      <TabPanel items={filteredTabs} activeTabIndex={activeTabIndex} onTabClick={setActiveTabIndex} />
      {(isOCRRequestViewPermitted && isOcrRequestDialogOpen) &&
        <Dialogs.AddOcrRequestDialog
          recordId={user.id}
          isOpened={isOcrRequestDialogOpen}
          onClose={handleOCRRequestDialogClose}
          onSubmit={handleAddOCRRequestSubmit}
        />
      }
      {(isNotificationEditPermitted && isNotificationDialogOpen) &&
        <Dialogs.AddNotificationDialog
          userId={user.id}
          isOpened={isNotificationDialogOpen}
          onClose={handleNotificationDialogClose}
          onSubmit={handleNotificationSubmit}
          defaultPhoneNumber={user.phone_number}
          defaultEmailAddress={user.email}
        />}
      {(isCollectionCallEditPermitted && isPhoneBookViewPermitted && isCollectionCallDialogOpen) &&
        <Dialogs.CollectionCallFormDialog
          userId={user.id}
          isOpened={isCollectionCallDialogOpen}
          onClose={handleCollectionCallDialogClose}
          onSubmit={handleCollectionCallSubmit}
        />}
      {(isPhoneBookEditPermitted && isEditUserContactsDialogOpen) &&
        <Dialogs.EditUserContactsDialog
          isOpened={isEditUserContactsDialogOpen}
          permissions={permissions}
          onClose={handleEditUserContactsDialogClose}
          onSubmit={handleEditUserContactSubmit}
          defaultPhoneNumber={user.phone_number}
        />}
      {(isNoteEditPermitted && isNoteDialogOpen) &&
        <Dialogs.AddNoteDialog
          isOpened={isNoteDialogOpen}
          onClose={handleNoteDialogClose}
          onSubmit={handleAddNoteSubmit}
        />}
      {(isUserAPIDataEditPermitted && isUserApiDataDialogOpen) && (
        <Dialogs.AddUserApiDataDialog
          providers={providersList}
          userId={user.id}
          isOpened={isUserApiDataDialogOpen}
          onClose={handleUserApiDataDialogClose}
          onSubmit={handleAddUserApiDataSubmit}
        />
      )}
    </>
  );
};

UserEditAdditionalInfo.propTypes = {
  user: shape({
    id: number,
    phone_number: string,
    email: string,
  }),
  activeTabIndex: number,
  setActiveTabIndex: func,
};
