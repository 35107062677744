import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions, useRefresh } from 'react-admin';
import { CircularProgress, TableRow, TableBody, Table, TableCell, Chip, Link, Button } from '@material-ui/core';

import EditSocialMediaDialog from './EditSocialMediaDialog';
import AddSocialMediaDialog from './AddSocialMediaDialog';

const SocialMedia = ({ userId }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);

  const [refreshedAt, setRefreshedAt] = useState(Date());

  const refresh = () => {
    setRefreshedAt(Date());
  };

  const [socialMedias, setSocialMedias] = useState();

  const [activeSocialMedia, setActiveSocialMedia] = useState();

  const { permissions = [] } = usePermissions();

  const isEditSocialMediaPermitted = permissions.includes('CAN_MAIN_PHONE_BOOK_EDIT');

  const [isSocialMediasDialogOpen, setIsSocialMediasDialogOpen] = useState(false);

  const onClosedDialogHandler = () => setIsSocialMediasDialogOpen(false);

  const onAddHandler = socialMedia => {
    socialMedia.type = socialMedia.type.toLowerCase();
    dataProvider
      .query('social_media', {
        method: 'POST',
        body: JSON.stringify({
          user_id: userId,
          ...socialMedia,
        }),
      })
      .then(() => {
        onClosedDialogHandler();
        refresh();
      })
      .catch(error => notify(`Error: ${error.message || 'social media POST error'}`, 'error'))
      .finally(() => setLoading(false));
  };

  const onEditHandler = socialMedia => {
    socialMedia.type = socialMedia.type.toLowerCase();
    dataProvider
      .query(`social_media/${socialMedia.id}`, { method: 'PATCH', body: JSON.stringify(socialMedia) })
      .then(() => {
        onClosedDialogHandler();
        refresh();
      })
      .catch(error => notify(`Error: ${error.message || 'social media PATCH error'}`, 'error'))
      .finally(() => setLoading(false));
    onClosedDialogHandler();
  };

  useEffect(() => {
    dataProvider
      .query(`social_media?user.id=${userId}`, { method: 'GET' })
      .then(({ data }) => setSocialMedias(data))
      .catch(error => notify(`Error: ${error.message || 'social media not found'}`, 'error'))
      .finally(() => setLoading(false));
  }, [dataProvider, notify, userId, refreshedAt]);

  if (loading) return <CircularProgress />;

  return (
    <>
      <Button
        color='primary'
        size='small'
        variant='contained'
        onClick={() => {
          setActiveSocialMedia();
          setIsSocialMediasDialogOpen(true);
        }}
      >
        Add
      </Button>

      {isSocialMediasDialogOpen && (
        <>
          {activeSocialMedia
            ? (
              <EditSocialMediaDialog
                isOpened={isSocialMediasDialogOpen}
                onClose={onClosedDialogHandler}
                socialMedia={activeSocialMedia}
                onSubmit={onEditHandler}
              />
            )
            : (
              <AddSocialMediaDialog
                isOpened={isSocialMediasDialogOpen}
                onClose={onClosedDialogHandler}
                onSubmit={onAddHandler}
              />
            )}
        </>
      )}

      <Table size='small'>
        <TableBody>
          {socialMedias.length !== 0 &&
            socialMedias.map(socialMedia => {
              const { id, type, uri, is_active } = socialMedia;
              const formattedName = type.slice(0, 1).toUpperCase() + type.slice(1).split('_').join(' ');

              return (
                <TableRow key={id}>
                  <TableCell>
                    <Link href={uri}>{formattedName}</Link>
                  </TableCell>
                  <TableCell align='right'>
                    <Chip label={String(is_active)} />
                  </TableCell>
                  <TableCell align='right'>
                    <Button
                      disabled={!isEditSocialMediaPermitted}
                      color='primary'
                      size='small'
                      variant='contained'
                      onClick={() => {
                        setActiveSocialMedia(socialMedia);
                        setIsSocialMediasDialogOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};

SocialMedia.propTypes = {
  userId: PropTypes.number,
};

export default SocialMedia;
