import React from 'react';
import PropTypes from 'prop-types';
import { Create, SelectInput, SaveButton, FormWithRedirect } from 'react-admin';
import { FormSpy } from 'react-final-form';
import { Card, Grid, Divider, Typography, CardHeader, CardContent } from '@material-ui/core';

import { TIMEZONE_OFFSET } from '../../../utils';

import { validators } from './ReportInputs.validation';
import { ReportInput } from './ReportInput';

const ParamsInput = builderId => builderId ? <ReportInput fieldName={builderId} /> : <div>Empty input</div>;

ParamsInput.propTypes = {
  builderId: PropTypes.string,
};

const roleToChoices = [
  { role: 'CAN_REPORT_CREDITINFO', value: { id: 'creditinfo_report', name: 'Creditinfo report' } },
  { role: 'CAN_REPORT_AFFILIATE_CREDITS', value: { id: 'affiliate_credits', name: 'Affiliate credits' } },
  { role: 'CAN_REPORT_INCOME', value: { id: 'income_report', name: 'Incomes' } },
  { role: 'CAN_REPORT_SUMMARY', value: { id: 'summary_report', name: 'Summary report' } },
  { role: 'CAN_REPORT_LOAN', value: { id: 'loan_report', name: 'Loans' } },
  { role: 'CAN_REPORT_CLOSED_LOAN', value: { id: 'closed_loan_report', name: 'Closed loan report' } },
  { role: 'CAN_REPORT_COLLECTION_LOAN', value: { id: 'collection_loan_report', name: 'Collection loan report' } },
  { role: 'CAN_REPORT_COMPLIANCE', value: { id: 'compliance_report', name: 'Compliance report' } },
  { role: 'CAN_REPORT_APPLICATION', value: { id: 'application_report', name: 'Applications' } },
  { role: 'CAN_REPORT_CASHFLOW_REPORT', value: { id: 'cashflow_report', name: 'Cashflow report' } },
  { role: 'CAN_REPORT_SECURITY_REPORT', value: { id: 'security_report', name: 'Security report' } },
  { role: 'CAN_REPORT_CLIENT', value: { id: 'client_report', name: 'Client report' } },
  { role: 'CAN_REPORT_TRANSACTION', value: { id: 'transaction_report', name: 'Transaction report' } },
  { role: 'CAN_REPORT_EXTENSION_REPORT', value: { id: 'extension_report', name: 'Extension report' } },
  { role: 'CAN_REPORT_NOTIFICATION', value: { id: 'notification_report', name: 'Notification report' } },
  { role: 'CAN_REPORT_LOCAL_NOTIFICATION', value: { id: 'local_notification_report', name: 'Local notification report' } },
  { role: 'CAN_REPORT_SECURITY_APPLICATION', value: { id: 'security_application_report', name: 'Security application report' } },
  { role: 'CAN_REPORT_PROMO_CODES', value: { id: 'promo_codes_report', name: 'Promo codes' } },
  { role: 'CAN_REPORT_UNFINISHED_REGISTRATION', value: { id: 'unfinished_registration_report', name: 'Unfinished registration report' } },
  { role: 'CAN_REPORT_AFFILIATE_EVENT', value: { id: 'affiliate_event_report', name: 'Affiliate event report' } },
  { role: 'CAN_REPORT_VERIFICATION_STAFF_STATISTICS', value: { id: 'verification_staff_statistics_report', name: 'Verification staff statistics report' } },
  { role: 'CAN_COLLECTION_EMAIL_REPORT', value: { id: 'collection_email_report', name: 'Collection email report' } },
  { role: 'CAN_REPORT_PROMO_CODE_SMS_REPORT', value: { id: 'promo_code_sms_report', name: 'Promo code sms report' } },
  { role: 'CAN_REPORT_COLLECTION_ASSIGN', value: { id: 'collection_assign_report', name: 'Collection assign report' } },
  { role: 'CAN_REPORT_COLLECTION_CALL', value: { id: 'collection_call_report', name: 'Collection call report' } },
  { role: 'CAN_REPORT_ACCOUNTING_LOAN', value: { id: 'accounting_loan_report', name: 'Accounting loan report' } },
  { role: 'CAN_REPORT_PAID_LOAN', value: { id: 'accounting_paid_report', name: 'Accounting paid report' } },
  { role: 'CAN_REPORT_UNPAID_LOAN', value: { id: 'accounting_unpaid_report', name: 'Accounting unpaid report' } },
  { role: 'CAN_REPORT_TOTAL_LOAN', value: { id: 'accounting_total_report', name: 'Accounting total report' } },
  { role: 'CAN_REPORT_CREDIT_BUREAU', value: { id: 'credit_bureau_report', name: 'Credit bureau report' } },
  // { role: 'CAN_REPORT_FUNCTIONAL_LINK_LOG', value: { id: 'functional_link_log_report', name: 'Functional link log report' } },
  { role: true, value: { id: 'functional_link_log_report', name: 'Functional link log report' } },
  { role: 'CAN_REPORT_UNRESOLVED_TARGETS_URLS', value: { id: 'unresolved_target_urls_report', name: 'Unresolved targets urls' } },

];

const FormWrapper = ({ permissions, save, ...props }) => {
  const choices = [];
  for (const value of roleToChoices) {
    if (permissions.indexOf(value.role) !== -1 || value.role === true) {
      choices[choices.length] = value.value;
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={8}>
        <Card>
          <CardHeader title={'Create report'} />
          <Divider />
          <CardContent>
            <FormWithRedirect
              validate={({ builder_id, ...params }) => {
                const errors = validators[builder_id] && validators[builder_id](params[builder_id] || {});
                return errors && Object.keys(errors).length ? { [builder_id]: errors } : {};
              }}
              save={ ({ builder_id, ...options }, ...rest) => {
                const params = options?.[builder_id] ? { ...options[builder_id] } : {};
                if (params.timezone && params.start_date instanceof Date && params.end_date instanceof Date && TIMEZONE_OFFSET[params.timezone]) {
                  const offset = TIMEZONE_OFFSET[params.timezone]() + new Date().getTimezoneOffset();
                  const startDate = new Date(new Date(params.start_date).valueOf() - offset * 60 * 1000);
                  const endDate = new Date(new Date(params.end_date).valueOf() - offset * 60 * 1000);
                  params.start_date = startDate.toISOString();
                  params.end_date = endDate.toISOString();
                }
                save(...[{ builder_id, params }, ...rest]);
              }}
              {...props}
              render={(formProps) => (
                <>
                  <SelectInput helperText={false} fullWidth source={'builder_id'} variant={'outlined'} choices={choices} />
                  <Typography variant={'h6'} gutterBottom={false}>Params</Typography>
                  <FormSpy subscription={{ touched: true, values: {} }}>
                    {({ values: { builder_id: builderId } }) => ParamsInput(builderId) }
                  </FormSpy>
                  <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
                  <SaveButton
                    label={'Submit'}
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </>
              )}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  permissions: PropTypes.array,
  save: PropTypes.func,
};

export const ReportCreate = ({ permissions = [], ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper permissions={ permissions } />
  </Create>
);

ReportCreate.propTypes = {
  permissions: PropTypes.array,
};
