import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import typeList from './socialTypes.list';

const AddSocialMediaDialog = ({ isOpened, onClose, onSubmit }) => {
  const [socialMedia, setSocialMedia] = useState({
    is_active: true,
  });

  const handleSubmit = () => onSubmit({ ...socialMedia });

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Add social medias</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id={'locale-select-label'}>Type</InputLabel>
              <Select
                label={'Type'}
                labelId={'transmitter-select-label'}
                value={socialMedia?.type}
                name={'type'}
                required
                onChange={e =>
                  setSocialMedia(prev => ({
                    ...prev,
                    type: e.target.value,
                  }))
                }
              >
                {typeList.map(({ label, value }, idx) => (
                  <MenuItem key={idx} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant={'outlined'}
              label={'URI'}
              value={socialMedia?.uri}
              autoComplete={'false'}
              name={'uri'}
              required
              onChange={e =>
                setSocialMedia(prev => ({
                  ...prev,
                  uri: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id={'locale-select-label'}>Is active</InputLabel>
              <Select
                label={'Is active'}
                variant={'outlined'}
                labelId={'locale-select-label'}
                value={socialMedia?.is_active}
                required
                onChange={e =>
                  setSocialMedia(prev => ({
                    ...prev,
                    is_active: e.target.value,
                  }))
                }
              >
                <MenuItem value={true}>true</MenuItem>
                <MenuItem value={false}>false</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit()}
          color='primary'
          style={{
            marginTop: '15px',
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSocialMediaDialog;
