export default [
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'Linkedin',
    value: 'linkedin',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
